.fixed-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100vh; /* Full height */
    overflow-y: auto; /* Add scroll if content overflows */
    z-index: 1000; /* Ensure it stays on top */
    background-color: rgba(0, 0, 0, .5); /* Set a background color */
    /* border-right: 1px solid #ddd; */
}