@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

* {
    font-family: "Kanit", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

body {
    margin: 0;
    padding: 0;
    /* background-color: #1e1e2f; */
    /* background: radial-gradient(
        ellipse at center,
        rgba(125, 0, 0, 1) 0%,
        rgba(125, 0, 0, 1) 3%,
        rgba(33, 0, 0, 1) 91%,
        rgba(33, 0, 0, 1) 100%
    ); */
    background-attachment: fixed;
    color: white;
    -ms-overflow-style: none;
}

body::-ms-scrollbar {
    width: 12px;
    background: transparent;
}

body::-ms-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

a:link {
    color: #7989ff;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

a:visited {
    color: #606dcc;
    text-decoration: none;
}

a:hover {
    color: #2a5478;
    text-decoration: none;
}

a .underline:hover {
    text-decoration: underline;
}

a:active {
    color: #7989ff;
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 12px; /* ความกว้างของ scrollbar */
}

::-webkit-scrollbar-track {
    background: transparent; /* สีพื้นหลังของ track */
}

::-webkit-scrollbar-thumb {
    background-color: #888; /* สีของ thumb (ตัวที่เลื่อน) */
    border-radius: 10px; /* มุมโค้งของ thumb */
    border: 3px solid transparent; /* ขอบของ thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* สีของ thumb เมื่อ hover */
}
